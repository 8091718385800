import { globalCss } from '.';

export const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },

  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '$sm',
  },

  body: {
    '-webkit-font-smoothing': 'antialiased',
    backgroundColor: '$pure',
  },

  '@font-face': [
    {
      fontFamily: 'Rawline',
      src: 'url("/fonts/rawline-400.ttf") format("truetype")',
      fontWeight: '400',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Rawline',
      src: 'url("/fonts/rawline-500.ttf") format("truetype")',
      fontWeight: '500',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Rawline',
      src: 'url("/fonts/rawline-600.ttf") format("truetype")',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Rawline',
      src: 'url("/fonts/rawline-700.ttf") format("truetype")',
      fontWeight: '700',
      fontStyle: 'normal',
    },
  ],

  'body, input, textarea, button': {
    fontFamily: 'Rawline',
    fontWeight: 400,
  },
  a: {
    fontFamily: 'Rawline',
    fontWeight: 400,
    color: '$blueWarmVivid70',
    textUnderlineOffset: '0.190rem',
  },
});
