'use client';
import { signOut, useSession } from 'next-auth/react';
import { ReactNode, useCallback, useEffect } from 'react';
import { CustomSession } from '@/types';

export default function SessionGuard({ children }: { children: ReactNode }) {
  const { data: session } = useSession() as { data: CustomSession | null };

  const handleLogout = useCallback(async () => {
    if (
      session?.accessToken === 'RefreshAccessTokenError' &&
      session?.idToken
    ) {
      try {
        const endSessionEndPoint = new URL(
          `${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUER}/protocol/openid-connect/logout`
        );

        const params: Record<string, string> = {
          id_token_hint: session.idToken,
          post_logout_redirect_uri: process.env.NEXTAUTH_URL!,
        };

        const endSessionParams = new URLSearchParams(params);

        console.log('dsdsd', `${endSessionEndPoint.href}?${endSessionParams}`);

        await signOut({ redirect: false });
        window.location.href = `${endSessionEndPoint.href}?${endSessionParams}`;
      } catch (error) {
        console.log(error);
        await signOut({ redirect: false });
        window.location.href = '/';
      }
    }
  }, [session?.accessToken, session?.idToken]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <>{children}</>;
}
